import "../style/header.css";
import logoImg from "../assets/images/logo.png";
import ReactGA from "react-ga";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Close, Menu } from "@material-ui/icons";

const HeaderPage = () => {
  const [flag, setFlag] = useState(false);
  const closeNav = () => {
    document.getElementById("myNav").style.width = "0%";
  };
  const homeClicked = () => {
    ReactGA.event({
      category: "Links",
      action: "Home",
      label: "Home link clicked!",
      value: 1,
    });
  };
  const handleMenu = () => {
    setFlag(true);
  };
  const handleClose = () => {
    setFlag(false);
  };
  return (
    <div className="header">
      <div className="header-container">
        <div className="header-left">
          <div className="logo">
            <img src={logoImg} style={{ width: "100%" }} />
          </div>
          <div className="flexagn">FLEXAGN</div>
        </div>
        <div className="header-right-2">
          <div className="header-right-2-container">
            {/* <a href="/" className="menu-item" onClick={homeClicked}>
              Home
            </a> */}

            <Link className="menu-item" to="/flexgenie">
              Schools
            </Link>
            <a
              href="http://blogflexagn.wordpress.com/"
              target="_blank"
              className="menu-item"
            >
              Blog
            </a>
          </div>
        </div>
        {flag && (
          <div className="mobile-menu-wrapper">
            <div className="header-right-mobile">
              <div className="header-right-2-mobile">
                {/* <a href="/" className="menu-item" onClick={homeClicked}>
                  Home
                </a> */}

                <Link className="menu-item" to="/flexgenie">
                  Schools
                </Link>
                <a
                  href="http://blogflexagn.wordpress.com/"
                  target="_blank"
                  className="menu-item"
                >
                  Blog
                </a>
              </div>
            </div>
          </div>
        )}
        {!flag ? (
          <Menu id="mobile-menu" onClick={handleMenu} />
        ) : (
          <Close id="mobile-close" onClick={handleClose} />
        )}
        <div id="myNav" class="overlay">
          <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>
            &times;
          </a>
          <div class="overlay-content">
            <a href="#">About</a>
            <a href="#">Services</a>
            <a href="#">Clients</a>
            <a href="#">Contact</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderPage;
