import '../style/why-choose-flexagn.css';
import '../style/wcf-card.css'

import img1 from '../assets/images/wcf-card/img-1.png';
import img2 from '../assets/images/wcf-card/img-2.png';
import img3 from '../assets/images/wcf-card/img-3.png';
import img4 from '../assets/images/wcf-card/img-4.png';
import octo from '../assets/images/octopus.png';
import mask from '../assets/images/mask.png'
import { explore_link } from '../constants/links';

import React from 'react';

const content = [
  {
    title: "Bite-sized content",
    body: "Bite-sized learning is focused on one learning objective at a time. Learn curriculum concepts using short comics, storybooks, and mini-games"
  },
  {
    title: "Fresh content everyday",
    body: "Daily learning is now interesting and fun with adaptive and personalized non-repetitive content every day. Keep learning new concepts and earn exclusive gems"
  },
  {
    title: "Playgrounds",
    body: "Kids can engage with concept-based learning games and simulations for an interactive and immersive learning experience"
  },
  {
    title: "Collaborative Learning",
    body: "Complete in-game missions, help friends and learn together via group projects and earn rewards"
  },
];

class WcfPage  extends React.Component {
    render() {
        return (
            <div className="wcf">
          <div className="wcf-container">
            <div className="octopus">
              <img src={octo} style={{width: '100%',display:'none'}} />
            </div>
            <div className="wcf-container-left">
              <div className="wcf-container-left-container">
                <div className="mask">
                  <img src={mask} style={{width: '100%'}} />
                </div>
                <div className="text-1" style={{marginBottom: '17px'}}>
                Turn your kids' mobile game time to learning time via Flexagn.
                </div>
                <div className="text-2" style={{marginBottom: '0'}}>
                A daily learning app for kids in grades 3-8 filled with creative and interactive content! Our fun, child-friendly, immersive educational content fuels their curiosity. Kids can now study Maths, Science, English & Computers concepts daily using bite-sized content such as comics, storybooks, simulations & games.
                </div>
                <div className='desktopOnly'>
                <a href={explore_link} target='_blank' style={{textDecoration: "none", color: "#185DD2"}}
                class="explore-btn">EXPLORE OUR APP</a>
                  {/* <button className="explore-btn">
                    EXPLORE OUR APP
                  </button> */}
                </div>
              </div>
            </div>
            <div className="wcf-container-right">
              {/* <!-- card-1 --> */}
              <div className="wcf-card">
                <div className="card-container">
                  <div className="card-img">
                    <div className="card-img-container">
                      <img src={img1} className="card-img" />
                    </div>
                  </div>
                  <div className="card-header">
                    {content[0].title}
                  </div>
                  <div className="card-description">
                    {content[0].body}
                  </div>
                </div>
              </div>
              {/* <!-- card-2 --> */}
              <div className="wcf-card">
                <div className="card-container">
                  <div className="card-img">
                    <div className="card-img-container">
                      <img src={img2} className="card-img" />
                    </div>
                  </div>
                  <div className="card-header">
                  {content[1].title}
                  </div>
                  <div className="card-description">
                    {content[1].body}
                  </div>
                </div>
              </div>
              {/* <!-- card-3 --> */}
              <div className="wcf-card">
                <div className="card-container">
                  <div className="card-img">
                    <div className="card-img-container">
                      <img src={img3} className="card-img" />
                    </div>
                  </div>
                  <div className="card-header">
                  {content[2].title}
                  </div>
                  <div className="card-description">
                    {content[2].body}
                  </div>
                </div>
              </div>
              {/* <!-- card-4 --> */}
              <div className="wcf-card">
                <div className="card-container">
                  <div className="card-img">
                    <div className="card-img-container">
                      <img src={img4} className="card-img" />
                    </div>
                  </div>
                  <div className="card-header">
                  {content[3].title}
                  </div>
                  <div className="card-description">
                    {content[3].body}
                  </div>
                </div>
              </div>
                <div className='onlyMobile'>
                  <a href={explore_link} style={{ textDecoration: "none", color: "#185DD2" }}
                    class="explore-btn">EXPLORE OUR APP</a>
                  {/* <button className="explore-btn">
                    EXPLORE OUR APP
                  </button> */}
                </div>
            </div>
          </div>
        </div>
        );
    }
}

export default WcfPage;