import React from "react";
import AppBar from "../../components/appbar/AppBar";
import ContactComponent from "../../components/contact_component/ContactComponent";
import DownloadComponent from "../../components/download_component/DownloadComponent";
import FAQ from "../../components/faq_component/FAQ";
import Features from "../../components/features_component/Features";
import Home from "../../components/home_component/Home";
import KeyPoint from "../../components/key_point_component/KeyPoint";
import ScholarshipComponent from "../../components/scholarship_component/ScholarshipComponent";
import SubscriptionComponent from "../../components/subscription_component/SubscriptionComponent";
import AppFooter from "../app-footer";
import NewsPage from "../news";

const FlexGeniePage = () => {
  return (
    <>
      <AppBar />
      <Home />
      <KeyPoint />
      {/* <OlpPage /> */}
      <Features />
      {/* <AboutUsPage /> */}
      <SubscriptionComponent />
      {/* <TestimonialsPage /> */}
      <ScholarshipComponent />
      <NewsPage />
      <DownloadComponent />
      <FAQ />
      <ContactComponent />
      <AppFooter />
    </>
  );
};

export default FlexGeniePage;
