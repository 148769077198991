import "../../style/header.css";

import logoImg from "../../assets/flexgenie_images/logo.png";

import ReactGA from "react-ga";

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";

// import ContactUsPage from './contact-us';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Close, Menu } from "@material-ui/icons";

const AppBar = () => {
  const [flag, setFlag] = useState(false);
  const closeNav = () => {
    document.getElementById("myNav").style.width = "0%";
  };
  const homeClicked = () => {
    ReactGA.event({
      category: "Links",
      action: "Home",
      label: "Home link clicked!",
      value: 1,
    });
  };

  const handleMenu = () => {
    setFlag(true);
  };
  const handleClose = () => {
    setFlag(false);
  };

  return (
    <div className="header">
      <div className="header-container">
        <div className="header-left">
          <div className="logo-flexgenie">
            <img src={logoImg} style={{ width: "100%" }} />
          </div>
          <div className="flexagn">Flex Genie</div>
        </div>

        <div className="header-right-2">
          <div className="header-right-2-container">
            {/* <a
              href="/flexgenie"
              className="menu-item-flexgenie"
              onClick={homeClicked}
            >
              Home
            </a> */}

            <a className="menu-item-flexgenie" href="/">
              Learning App
            </a>
          </div>
        </div>
        {flag && (
          <div className="mobile-menu-wrapper">
            <div className="header-right-mobile">
              <div className="header-right-2-mobile">
                {/* <a
                  href="/flexgenie"
                  className="menu-item-flexgenie"
                  onClick={homeClicked}
                >
                  Home
                </a> */}

                <a className="menu-item-flexgenie" href="/">
                  Learning App
                </a>
              </div>
            </div>
          </div>
        )}
        {!flag ? (
          <Menu id="mobile-menu-flexgenie" onClick={handleMenu} />
        ) : (
          <Close id="mobile-close-flexgenie" onClick={handleClose} />
        )}
        <div id="myNav" class="overlay">
          <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>
            &times;
          </a>
          <div class="overlay-content">
            <a href="#">About</a>
            <a href="#">Services</a>
            <a href="#">Clients</a>
            <a href="#">Contact</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppBar;
