import "../style/our-subscription-model.css";
import "../style/subscription-card/subscription-card.css";

import frogImg from "../assets/images/woolly-frog.png";
import gameImg from "../assets/images/subscription-card/game.png";
import movieImg from "../assets/images/subscription-card/movie.png";
import mealImg from "../assets/images/subscription-card/meal.png";
import { explore_link } from "../constants/links";

import React from "react";

const content = [
  {
    title: "For 12 months",
    body: "Lesser than the price of a movie",
  },
  {
    title: "For 6 months",
    body: "Lesser than the price of a meal",
  },
  {
    title: "For 3 months",
    body: "Lesser than the price of a game",
  },
];

class SubscriptionPage extends React.Component {
  render() {
    return (
      <div className="osm">
        <div className="osm-container">
          <div className="osm-header">
            <div className="osm-header-container">
              <div className="osm-header-left">
                <img
                  src={frogImg}
                  alt=""
                  width="100%"
                  style={{ width: "100%", maxHeight: "550px" }}
                />
              </div>
              <div className="osm-header-right">
                <div className="osm-header-right-container">
                  <div className="osm-header">
                    World-class Content. Affordable Pricing
                  </div>
                  <div className="osm-sub-header">
                    Flexagn is offering Maths, Science concepts & more with
                    unlimited access. New learning modules every day. Cancel
                    anytime and get a full refund*
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="osm-body">
            <div>
              <p className="osm-body-header">Our Subscription Model</p>
              <p className="osm-body-subheader">
                We offer affordable and flexible plans for unlimited access, and
                new content every day.
              </p>
            </div>
            <div />
            <div className="osm-body-container">
              {/* <!-- subs-card --> */}
              <div className="subs-card">
                <div className="subs-card-container">
                  <div className="subs-card-1-meta-tag">
                    <div className="subs-card-1-meta-tag-container">
                      RECOMMENDED
                    </div>
                  </div>
                  <div className="subs-card-2-header">{content[0].title}</div>
                  <div className="subs-card-3-description">
                    {content[0].body}
                  </div>
                  <div className="subs-card-4-img">
                    <img
                      src={movieImg}
                      className="subscription-img"
                      alt="loading..."
                    />
                  </div>
                  <div className="subs-card-5-footer">(Almost Rs.2/day)</div>
                </div>
              </div>
              {/* <!-- subs-card --> */}
              <div className="subs-card">
                <div className="subs-card-container">
                  <div className="subs-card-1-meta-tag"></div>
                  <div className="subs-card-2-header">{content[1].title}</div>
                  <div className="subs-card-3-description">
                    {content[1].body}
                  </div>
                  <div className="subs-card-4-img">
                    <img
                      src={mealImg}
                      className="subscription-img"
                      alt="loading..."
                    />
                  </div>
                  <div className="subs-card-5-footer"></div>
                </div>
              </div>
              {/* <!-- subs-card --> */}
              <div className="subs-card">
                <div className="subs-card-container">
                  <div className="subs-card-1-meta-tag"></div>
                  <div className="subs-card-2-header">{content[2].title}</div>
                  <div className="subs-card-3-description">
                    {content[2].body}
                  </div>
                  <div className="subs-card-4-img">
                    <img
                      src={gameImg}
                      className="subscription-img"
                      alt="loading..."
                    />
                  </div>
                  <div className="subs-card-5-footer"></div>
                </div>
              </div>
              <div className="osm-footer">
                <div className="osm-footer-container">
                  <a
                    href={explore_link}
                    target="_blank"
                    style={{ textDecoration: "none", color: "white" }}
                    class="osm-explore-btn"
                  >
                    EXPLORE
                  </a>
                  {/* <button className="osm-explore-btn">EXPLORE</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionPage;
