import '../style/news.css';

import newsBrandImg from '../assets/images/news-paper/news-paper-logo.png';
import brushImg from '../assets/images/woolly-brush.png';
import beeImg from '../assets/images/woolly-bee.png';

import React from 'react';

class NewsPage  extends React.Component {
    render() {
        return (
            <div className="news" style={{display: 'none'}}>
      <div className="news-container">
        <div className="news-header">
          <div className="news-header-container">
          As featured in
            <div className="brush">
              <img src={brushImg} style={{width: '100%'}} />
            </div>
          </div>
        </div>
        <div className="news-body">
          <div className="news-body-container">
            <div className="news-paper-brand">
              <img src={newsBrandImg} alt="" style={{width: '85%', maxWidth: '280px', minWidth: '170px'}} />
            </div>
            <div className="news-paper-brand">
              <img src={newsBrandImg} alt="" style={{width: '85%', maxWidth: '280px', minWidth: '170px'}} />
            </div>
            <div className="news-paper-brand">
              <img src={newsBrandImg} alt="" style={{width: '85%', maxWidth: '280px', minWidth: '170px'}} />
            </div>
            <div className="news-paper-brand">
              <img src={newsBrandImg} alt="" style={{width: '85%', maxWidth: '280px', minWidth: '170px'}} />
            </div>
          </div>
        </div>
        <div className="news-footer">
          <div className="news-footer-container">
            <img src={beeImg} width="50%" style={{width: '50%', maxWidth: '90px'}} />
          </div>
        </div>
      </div>
    </div>
        );
    }
}

export default NewsPage;