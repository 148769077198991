import React, { useState } from "react";
import flag from "../assets/images/flags/india.png";
import styled from "styled-components";
const Header = styled.span`
  font: normal normal 600 24px Poppins;
`;
const SubHeader = styled.span`
  font: normal normal normal 15px Poppins;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 10px;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font: normal normal 500 12px Poppins;
  margin-bottom: 4px;
`;
const InputContainer = styled.div`
  display: flex;
  width: 335px;
  height: 50px;
  align-items: center;
  border: 2px solid rgb(218, 244, 245);
  @media (max-width:700px){
    width:250px;
    height:30px;
  }
`;
const InputLeft = styled.div`
  display: flex;
  align-items: center;
  border-right: 2px solid rgb(218, 244, 245);
  padding: 4px;
`;
const Image = styled.img`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 4px;
`;
const Code = styled.span`
  font: normal normal 500 15px Poppins;
  margin-right: 10px;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 8px 12px;
  outline: none;
  border: none;
  font: normal normal 500 16px/25px Poppins;
  letter-spacing: 2px;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px/25px Poppins;
    color: #b5b5b5;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
const Button = styled.button`
  width: 335px;
  margin: auto;
  padding: 6px 12px;
  font: normal normal 600 16px Poppins;
  background-color: #185dd2;
  color: white;
  border: none;
  border-radius: 90px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 3px 6px #0000000d;
  @media (max-width:700px){
    width:250px;
  }
`;
const DisclaimerWrapper = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 30px;
`;
const Disclaimer = styled.span`
  font: normal normal normal 12px/18px Poppins;
  color: #b5b5b5;
`;
const Highlighter = styled.span`
  color: #185dd2;
`;

const LoginRight = (props) => {
  const [value,setValue]=useState({phone:""});
  const onChange=(e)=>{
    setValue({...value,[e.target.name]:e.target.value});
  }
  const handleClick = () => {
      props.setStatus(true);
      setValue({phone:""});
  };
  return (
    <>
        <Header>{props.data.header}</Header>
        <SubHeader>{props.data.subheader}</SubHeader>
        <Form>
          <Label>{props.data.label}</Label>
          <InputContainer>
            <InputLeft>
              <Image src={flag} alt="loading..." />
              <Code>{props.data.code}</Code>
            </InputLeft>
            <Input type="number" name="phone" id="phone" onChange={onChange} placeholder="Enter your phone number" />
          </InputContainer>
          <ButtonWrapper>
            <Button onClick={handleClick}>Continue</Button>
          </ButtonWrapper>
          <DisclaimerWrapper>
{
            props.data.disclaimer?
            <Disclaimer>{props.data.disclaimer} <a href="https://d2dsj7nm5j2dtv.cloudfront.net/tos.html" target="_blank" style={{textDecoration:"none"}}><Highlighter>{props.data.t1} </Highlighter></a>and <a href="https://d2dsj7nm5j2dtv.cloudfront.net/pp.html" target="_blank" style={{textDecoration:"none"}}><Highlighter>{props.data.t2} </Highlighter></a></Disclaimer>:""
}</DisclaimerWrapper>
        </Form>
      </>
  );
};

export default LoginRight;
