import "../../style/contact-us.css";

// import nineImg from "../../assets/images/nine.png";
// import tenImg from "../../assets/images/ten.png";
import envelopeImg from "../../assets/images/envelope.png";

import React, { useState } from "react";

const ContactComponent = () => {
  const [value, setValue] = useState({
    school_name: "",
    name: "",
    phone: "",
    city: "",
    message: "",
  });
  const onChange = (e) => {
    setValue({ ...value, [e.target.id]: e.target.value });
  };
  const handleClick = (e) => {
    setValue({ school_name: "", name: "", phone: "", city: "", message: "" });
  };
  return (
    <div className="contact-us-flexgenie" id="contact_us">
      <div className="contact-us-container">
        <div className="contact-us-left">
          <div className="contact-us-left-container">
            {/* <div className="number-nine-container">
              <img src={nineImg} id="nine" />
            </div>
            <div className="number-ten-container">
              <img src={tenImg} id="ten" />
            </div> */}
            <div className="envelope-img">
              <img src={envelopeImg} id="envelope" />
            </div>
            <div className="contact-text-container">
              <div className="contact-text-1">Contact Us!</div>
              <div className="contact-text-2">Address</div>
              <div className="contact-text-3">
                Bangalore, Karnataka 560094 - India
              </div>
              <div className="contact-text-2">Support</div>
              <div className="contact-text-3">
                Email - school-support@flexagn.com
              </div>
              <div className="contact-text-3">Whatsapp - 9886865380</div>
            </div>
          </div>
        </div>
        <div className="contact-us-right">
          <div className="contact-us-right-container">
            <div
              className="contact-form-container"
              style={{ backgroundColor: "#FAF2FA" }}
            >
              <div className="contact-form-header">Get in Touch!</div>

              <form
                onSubmit={handleClick}
                style={{ width: "100%" }}
                action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSc3Hx_R9KljTF7Rw378AM6I0Fk2PX22vsh3WGDYc9vJ95JU4w/formResponse"
                method="POST"
                target="_blank"
              >
                <div className="contact-form-input-body">
                  <div className="contact-form-label">School Name</div>
                  <input
                    type="text"
                    className="contact-form-input"
                    id="school_name"
                    name="entry.122308206"
                    required
                    minLength="5"
                    onChange={onChange}
                    value={value.school_name}
                  />
                </div>
                <div className="contact-form-input-body">
                  <div className="contact-form-label">Full Name</div>
                  <input
                    type="text"
                    className="contact-form-input"
                    id="name"
                    name="entry.376622522"
                    required
                    minLength="5"
                    onChange={onChange}
                    value={value.name}
                  />
                </div>
                <div className="contact-form-input-body">
                  <div className="contact-form-label">Mobile Number</div>
                  <input
                    type="number"
                    className="contact-form-input"
                    id="phone"
                    name="entry.338786798"
                    required
                    minLength="10"
                    onChange={onChange}
                    value={value.phone}
                  />
                </div>
                <div className="contact-form-input-body">
                  <div className="contact-form-label">City</div>
                  <input
                    type="text"
                    className="contact-form-input"
                    id="city"
                    name="entry.1190778406"
                    required
                    minLength="5"
                    onChange={onChange}
                    value={value.city}
                  />
                </div>
                <div className="contact-form-input-body">
                  <div className="contact-form-label">Message</div>
                  <textarea
                    type="textarea"
                    className="contact-form-textarea"
                    id="message"
                    name="entry.344629922"
                    required
                    minLength="8"
                    onChange={onChange}
                    value={value.message}
                  />
                </div>
                <div className="submit-btn-container">
                  <button
                    className="submit-btn"
                    style={{ width: "100%", backgroundColor: "#C23FA1" }}
                    type="submit"
                    value="Submit"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;
