const worksheetData=[
    {
        title:"Grade IV Multiplication",
        url:"https://drive.google.com/file/d/15xf5QGmAPpcvI4Knz1vm-7gQ22aoTV-O/view?usp=sharing"
    },
    {
        title:"Grade IV Numbers",
        url:"https://drive.google.com/file/d/15xf5QGmAPpcvI4Knz1vm-7gQ22aoTV-O/view?usp=sharing"
    },
    {
        title:"Grade IV Addition and Subtraction",
        url:"https://drive.google.com/file/d/12vWnFKVJJdq1tqLRT09e6DF2YhwUBNaS/view?usp=sharing"
    }
]
const Auth=[
    {
      login:{
        header:"Verify Number",
        subheader:"Please enter your mobile number to Verify.",
        label:"Phone Number",
        code:"+91"
      },
      otp:{
        header:"Please verify your phone number",
        subheader:"Enter the 4-digit code sent to you at +91 9992299399",
        disclaimer:"Resend OTP in 00:00 sec"
      } 
    }
  ]

export {worksheetData,Auth};