import * as React from 'react';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from '@mui/material/Typography';

import '../style/frequently-asked-questions.css';
import crabImg from '../assets/images/woolly-crab.png';

const faqs = [
    {
        question: "Why should I download the Flexagn app?",
        answer: " Flexagn app is a creative learning platform for daily learning for kids in grades 3-8.  Our product offering is based on a unique learning framework which combines the power of adaptive, exploratory and collaborative learning into one effective module which adds to their overall cognitive development and improves the learning outcomes of the child. Kids can learn curriculum concepts in bite sized modules through comics, storybooks, videos, simulations and games. Kids get access to new content everyday. Through an intuitive, game-like environment  learning on flexagn app is much more fun and interactive. Parents can keep track of their child’s learning progress through a unique parent’s dashboard."
    },
    {
        question: "What makes Flexagn different from other edtech providers?",
        answer: "Current educational content available on the internet is monotonous and in lengthy formats with less interaction resulting in kids losing interest and attention. Also the ed-tech models available are more focused on need-based learning. Flexagn is changing the way kids approach daily learning. We are on a mission to make learning a habit via creative and collaborative learning. Our unique content is less monotonous, more creative and interactive (comics, storybooks,  simulations, games and explorations) which makes the learning less boring and more productive. Through Flexagn,  kids will enjoy learning with minimal parent involvement.  Flexagn is the best gamed based learning platform for your child as we go beyond and above the existing edtech solutions."
    },
    {
        question: "Why learning in games is required?",
        answer: "Reports suggest that gamification of certain concepts drive upto 35% better retention with respect to learning engagements."
    },
    {
        question: "Can the content in the Flexagn be downloaded?",
        answer: "No, our content cannot be downloaded.  You need the app to interact and play all the learning games and simulations."
    },
    {
        question: "Is the app free?",
        answer: "We provide a 3-7 days free trial for all the users. Parents and kids can experience all the features and content in the app during the free trial."
    },
    {
        question: "What happens after the end of a free trial?",
        answer: "You will be offered easy and affordable subscription options at the end of the free trial.Our subscription prices are very affordable compared to other edtech apps. Please subscribe to one of the paid packages to continue maximize the benefits from our unique e-learning app."
    },
    {
        question: "Can I upgrade or cancel my subscription whenever I want?",
        answer: "You can choose to upgrade or cancel your subscription anytime. However, any subscription cancellations during the month will end only at the end of the month and only pro rata amount will be refunded."
    },
    {
        question: "Can I pay in installments?",
        answer: "No, at the moment we are not giving pay in installsments option. You have to pay the amount in total. Our subscription prices are very affordable compared to other edtech apps."
    },
    {
        question: "What are the subjects available?",
        answer: "We currently offer content for Maths, Science, English, and Computers for Grades 3-8 across CBSE /ICSE boards but not tightly coupled with the curriculum."
    },
    {
        question: "How do you cover the syllabus? And how do you clear doubts?",
        answer: "Even though Flexagn is a supplementary educational app to what is taught in school, our content  is still mapped to the ICSE, CBSE boards. We ensure that all concepts pertaining to the subject and grade are shown to the students.  While our standard academic year starts in April and ends in March of the next year, our advanced algorithm ensures that all content is covered regardless of when the student signs up  on Flexagn."
    },
    {
        question: "Do you provide any printed study material for enrolled students?",
        answer: "No, at the moment we do not provide any printed study materials for the kids. Once you subscribe the app, all the content including comics, storybooks are available to use anytime."
    },
    {
        question: "Once I subscribe to Flexagn,  how do I contact support for help?",
        answer: "Once you subscribe to Flexagn, you can contact us via Whatsapp, email. Our learning counsellors will be in touch with you to check your child's progress and help you with any questions."
    },
    {
        question: "How will I know about my child's learning progress?",
        answer: "We will be providing a unique parent dashboard for every child. This dashboard will give you the entire snapshot of your childs learning progress along with highlights and weaknesses."
    },
    {
        question: "What are gems?",
        answer: "Students can earn gems based on their progress and activities in Flexagn App. They earn gems for completing tasks, helping friends with tasks and learning daily. Students can view the Gems they’ve earned on the top right corner of the app."
    },
    {
        question: "What is marketplace?",
        answer: "Marketplace is an online store within the app where kids can redeem gems earned during learning in the app. Now kids can learn daily concepts everyday, earn gems and redeem them for exclusive items."
    },
    {
        question: "How will we collect the addresses of kids who have redeemed gifts?",
        answer: "The customer support team will reach out to the parents and collect addresses & other information."
    },
    {
        question: "How long does it take to deliver the redeemed gifts?",
        answer: "Please expect 3-4 weeks of time for delivering the redeemed gifts."
    },
    {
        question: "What is the process to replace/return the redeemed gifts?",
        answer: "Please reach out to the customer support team at support@flexagn.com with your questions."
    },
    {
        question: "Where can we check the order history?",
        answer: "In the app, go to the marketplace tab and click on order history."
    },
    {
        question: "Can we redeem gems for real cash?",
        answer: "No."
    },
    {
        question: "Where can I see my gems collected and redeemed?",
        answer: "You will see the same in the order history. In the app, go to the marketplace tab and click on order history."
    },
];



function FaqPage() {
    return (
        <div className="faq">
            <div className="faq-container">
                <div className="crab-img" style={{display:'none'}}>
                    <img src={crabImg} width="100%" />
                </div>
                <div className="faq-header">
                    <div className="faq-header-container">
                    Frequently Asked Questions
                    </div>
                </div>
                <div className="faq-body">
                    <div className="faq-body-container">
                        <div>
                            {
                                faqs.map((faq, i) => (
                                    <Accordion className="accordion">
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header">
                                            <Typography className="accordion-header" style={{fontWeight: 'medium', fontSize: '17px', fontFamily: 'Poppins'}}>{faq.question}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography className="accordion-body" style={{fontSize: '14px', fontFamily: 'Poppins'}}>
                                                {faq.answer}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaqPage;