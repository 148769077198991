import logo from "./logo.svg";
import "./App.css";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/base_pages/LandingPage";
import FlexGeniePage from "./pages/base_pages/FlexGeniePage";
const TRACKING_ID = "G-BSB9XEH4KW";
ReactGA.initialize(TRACKING_ID);
// console.log('initialized);

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/flexgenie" element={<FlexGeniePage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
