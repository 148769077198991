import '../style/testimonials.css';
import '../style/testimonial-card/testimonial-card.css'
import '../style/testimonial-card/n-testimonial-card.css'

import React from 'react';

import athenaImg from '../assets/images/athena.png';
import beesBearImg from '../assets/images/bees-and-bear.png';
// import videoImg from '../assets/images/video.png';
// import personImg from '../assets/images/person.png';
import motherImg from '../assets/images/mother.png';
import teacherImg from '../assets/images/teacher.png';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 30
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};



function TestimonialsPage() {
  return (
    <div className="testimonials" id='blog'>
      <div className="testimonials-container">
        <div className="testimonials-header">
          <div className="athena-img">
            <img  src={athenaImg} style={{ width: '90%', display: 'none' }} />
          </div>
          <div className="bees-and-bear-img">
            <img  src={beesBearImg} style={{ width: '90%', display: 'none' }} />
          </div>
          <div className="testimonials-header-container">
            Testimonials
          </div>
        </div>
        <div className="testimonials-body">
          <div className="testimonials-body-container">
            <div className="testimonial-cards" id="testimonial-animation">
              <div id="myCarousel" className="carousel slide" data-ride="carousel" data-interval='false'>
                <ol className="carousel-indicators carousel-indicators-1">
                  <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                  <li data-target="#myCarousel" data-slide-to="1"></li>
                  <li data-target="#myCarousel" data-slide-to="2"></li>
                  <li data-target="#myCarousel" data-slide-to="3"></li>
                  {/* <li data-target="#myCarousel" data-slide-to="4"></li>
                  <li data-target="#myCarousel" data-slide-to="5"></li>
                  <li data-target="#myCarousel" data-slide-to="6"></li> */}
                </ol>
                <div className="carousel-inner">
                  <div className="item active test-card">
                    <div className="testimonial-card">
                      <div className="parallelogram"></div>
                      <div className="testimonial-card-container">
                        <div className="testimonial-card-left">
                          <div className="testimonial-card-left-container">
                            <div className="video-img">
                              {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                            </div>
                            <img className='testimonials-img' src={motherImg}  />
                          </div>
                        </div>
                        <div className="testimonial-card-right">
                          <div className="testimonial-content">
                            Impressive! Definitely, it would catch the attention of kids as it has a lot of cartoon
                            characters!
                            In this era of the digital revolution, the “Learn while you play” motto is so well met! Quality
                            and
                            quantity at affordable cost. Would definitely recommend it!
                          </div>
                          <div className="testimonial-writer">
                            Veena,
                          </div>
                          <div className="testimonial-address">
                            Parent, Bengaluru
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item test-card">
                    <div className="testimonial-card">
                      <div className="parallelogram"></div>
                      <div className="testimonial-card-container">
                        <div className="testimonial-card-left">
                          <div className="testimonial-card-left-container">
                            <div className="video-img">
                              {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                            </div>
                            <img className='testimonials-img' src={motherImg}  />
                          </div>
                        </div>
                        <div className="testimonial-card-right">
                          <div className="testimonial-content">
                            My son likes the app very much, especially the comics which explain science concepts nicely.
                            Coding
                            & chess games are easy as he learns on his own with hints.
                          </div>
                          <div className="testimonial-writer">
                            Bhuvaneshwari,
                          </div>
                          <div className="testimonial-address">
                            Parent, Coimbatore
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item test-card">
                    <div className="testimonial-card">
                      <div className="parallelogram"></div>
                      <div className="testimonial-card-container">
                        <div className="testimonial-card-left">
                          <div className="testimonial-card-left-container">
                            <div className="video-img">
                              {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                            </div>
                            <img className='testimonials-img' src={motherImg}  />
                          </div>
                        </div>
                        <div className="testimonial-card-right">
                          <div className="testimonial-content">
                            This is an amazing experience for both parents and children. The parents will be happy that the
                            concepts are being taught so creatively and the children will love the learning experience due to
                            its presentation and versatility!
                          </div>
                          <div className="testimonial-writer">
                            Sapna,
                          </div>
                          <div className="testimonial-address">
                            Parent, Jammu
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item test-card">
                    <div className="testimonial-card">
                      <div className="parallelogram"></div>
                      <div className="testimonial-card-container">
                        <div className="testimonial-card-left">
                          <div className="testimonial-card-left-container">
                            <div className="video-img">
                              {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                            </div>
                            <img className='testimonials-img' src={teacherImg}  />
                          </div>
                        </div>
                        <div className="testimonial-card-right">
                          <div className="testimonial-content">
                            Learning is more effective when it is an active process. Children may forget when concepts are
                            just
                            simply explained but will not forget when they experience the concepts. Flexagn is a wonderful
                            learning platform for children designed to keep them updated with concepts through visual
                            learning.
                            The concepts are visualized in the most creative way that children would easily understand the
                            topics.
                          </div>
                          <div className="testimonial-writer">
                            Aparna,
                          </div>
                          <div className="testimonial-address">
                            Teacher, Ex Poddar School
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* normal screen */}
            <div className="testimonials-body-container-normal-1">
              <div className="testimonial-cards" id='box'>
                <div className="n-testimonial-card">
                  <div className="parallelogram"></div>
                  <div className="n-testimonial-card-container">
                    <div className="n-testimonial-card-left">
                      <div className="n-testimonial-card-left-container">
                        <div className="video-img">
                          {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                        </div>
                        <img className='testimonials-img' src={motherImg} style={{ width: '180px', height: '180px' }} />
                      </div>
                    </div>
                    <div className="n-testimonial-card-right">
                      <div className="n-testimonial-content">
                        Impressive! Definitely, it would catch the attention of kids as it has a lot of cartoon characters! In this era of the digital revolution, the “Learn while you play” motto is so well met! Quality and quantity at affordable cost. Would definitely recommend it!
                      </div>
                      <div className="n-testimonial-writer">
                        Veena,
                      </div>
                      <div className="n-testimonial-address">
                        Parent, Bengaluru
                      </div>
                    </div>
                  </div>
                </div>

                <div className="n-testimonial-card">
                  <div className="parallelogram"></div>
                  <div className="n-testimonial-card-container">
                    <div className="n-testimonial-card-left">
                      <div className="n-testimonial-card-left-container">
                        <div className="video-img">
                          {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                        </div>
                        <img className='testimonials-img' src={motherImg} style={{ width: '180px', height: '180px' }} />
                      </div>
                    </div>
                    <div className="n-testimonial-card-right">
                      <div className="n-testimonial-content">
                        My son likes the app very much, especially the comics which explain science concepts nicely. Coding & chess games are easy as he learns on his own with hints.
                      </div>
                      <div className="n-testimonial-writer">
                        Bhuvaneshwari,
                      </div>
                      <div className="n-testimonial-address">
                        Parent, Coimbatore
                      </div>
                    </div>
                  </div>
                </div>
                <div className="n-testimonial-card">
                  <div className="parallelogram"></div>
                  <div className="n-testimonial-card-container">
                    <div className="n-testimonial-card-left">
                      <div className="n-testimonial-card-left-container">
                        <div className="video-img">
                          {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                        </div>
                        <img className='testimonials-img' src={motherImg} style={{ width: '180px', height: '180px' }} />
                      </div>
                    </div>
                    <div className="n-testimonial-card-right">
                      <div className="n-testimonial-content">
                        This is an amazing experience for both parents and children. The parents will be happy that the concepts are being taught so creatively and the children will love the learning experience due to its presentation and versatility!
                      </div>
                      <div className="n-testimonial-writer">
                        Sapna,
                      </div>
                      <div className="n-testimonial-address">
                        Parent, Jammu
                      </div>
                    </div>
                  </div>
                </div>
                <div className="n-testimonial-card">
                  <div className="parallelogram"></div>
                  <div className="n-testimonial-card-container">
                    <div className="n-testimonial-card-left">
                      <div className="n-testimonial-card-left-container">
                        <div className="video-img">
                          {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                        </div>
                        <img className='testimonials-img' src={teacherImg} style={{ width: '180px', height: '180px' }} />
                      </div>
                    </div>
                    <div className="n-testimonial-card-right">
                      <div className="n-testimonial-content">
                        Learning is more effective when it is an active process. Children may forget when concepts are just simply explained but will not forget when they experience the concepts. Flexagn is a wonderful learning platform for children designed to keep them updated with concepts through visual learning. The concepts are visualized in the most creative way that children would easily understand the topics.
                      </div>
                      <div className="n-testimonial-writer">
                        Aparna,
                      </div>
                      <div className="n-testimonial-address">
                        Teacher, Ex Poddar School
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="testimonials-body-container-normal">
              <div className="testimonial-cards">
                <Carousel
                  ssr
                  partialVisbile={true}
                  deviceType={"desktop"}
                  responsive={responsive}
                  arrows={false}
                  showDots={true}
                  infinite={true}
                  autoPlay={false}
                  swipeable={true}
                  draggable={true}
                  autoPlaySpeed={1000000}
                >
                  <div>
                    <div className="n-testimonial-card">
                      <div className="parallelogram"></div>
                      <div className="n-testimonial-card-container">
                        <div className="n-testimonial-card-left">
                          <div className="n-testimonial-card-left-container">
                            <div className="video-img">
                              {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                            </div>
                            <img className='testimonials-img' src={motherImg} style={{ width: '180px', height: '180px' }} />
                          </div>
                        </div>
                        <div className="n-testimonial-card-right">
                          <div className="n-testimonial-content">
                            Impressive! Definitely, it would catch the attention of kids as it has a lot of cartoon characters! In this era of the digital revolution, the “Learn while you play” motto is so well met! Quality and quantity at affordable cost. Would definitely recommend it!
                          </div>
                          <div className="n-testimonial-writer">
                            Veena,
                          </div>
                          <div className="n-testimonial-address">
                            Parent, Bengaluru
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="n-testimonial-card">
                      <div className="parallelogram"></div>
                      <div className="n-testimonial-card-container">
                        <div className="n-testimonial-card-left">
                          <div className="n-testimonial-card-left-container">
                            <div className="video-img">
                              {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                            </div>
                            <img className='testimonials-img' src={motherImg} style={{ width: '180px', height: '180px' }} />
                          </div>
                        </div>
                        <div className="n-testimonial-card-right">
                          <div className="n-testimonial-content">
                            My son likes the app very much, especially the comics which explain science concepts nicely. Coding & chess games are easy as he learns on his own with hints.
                          </div>
                          <div className="n-testimonial-writer">
                            Bhuvaneshwari,
                          </div>
                          <div className="n-testimonial-address">
                            Parent, Coimbatore
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="n-testimonial-card">
                      <div className="parallelogram"></div>
                      <div className="n-testimonial-card-container">
                        <div className="n-testimonial-card-left">
                          <div className="n-testimonial-card-left-container">
                            <div className="video-img">
                              {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                            </div>
                            <img className='testimonials-img' src={motherImg} style={{ width: '180px', height: '180px' }} />
                          </div>
                        </div>
                        <div className="n-testimonial-card-right">
                          <div className="n-testimonial-content">
                            This is an amazing experience for both parents and children. The parents will be happy that the concepts are being taught so creatively and the children will love the learning experience due to its presentation and versatility!
                          </div>
                          <div className="n-testimonial-writer">
                            Sapna,
                          </div>
                          <div className="n-testimonial-address">
                            Parent, Jammu
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="n-testimonial-card">
                      <div className="parallelogram"></div>
                      <div className="n-testimonial-card-container">
                        <div className="n-testimonial-card-left">
                          <div className="n-testimonial-card-left-container">
                            <div className="video-img">
                              {/* <img className='testimonials-img' src={videoImg} style={{width: '100%'}} /> */}
                            </div>
                            <img className='testimonials-img' src={teacherImg} style={{ width: '180px', height: '180px' }} />
                          </div>
                        </div>
                        <div className="n-testimonial-card-right">
                          <div className="n-testimonial-content">
                            Learning is more effective when it is an active process. Children may forget when concepts are just simply explained but will not forget when they experience the concepts. Flexagn is a wonderful learning platform for children designed to keep them updated with concepts through visual learning. The concepts are visualized in the most creative way that children would easily understand the topics.
                          </div>
                          <div className="n-testimonial-writer">
                            Aparna,
                          </div>
                          <div className="n-testimonial-address">
                            Teacher, Ex Poddar School
                          </div>
                        </div>
                      </div>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </Carousel>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* <div className="bees-and-bear-img-bottom">
        <img className='testimonials-img' src={beesBearImg} style={{ width: '90%' }} />
      </div> */}
    </div>

  );
}

export default TestimonialsPage;