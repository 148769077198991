import '../style/home-screen.css';

import labImg from '../assets/images/lab.gif';
import clockImg from '../assets/images/clock.png';
import readingBoyImg from '../assets/images/reading-boy.gif';
import ideadImg from '../assets/images/idea.png';
// import bullseyeImg from '../assets/images/bullseye.png';
import bullseyeImg from '../assets/images/target.gif';
import { playstore_link } from '../constants/links';

import React from 'react';

class HomePage  extends React.Component {
    render() {
        return (
            <div className="home-screen" id='home'>
      <div className="home-screen-container">
        <div className="home-screen-left">
          <div className="home-screen-images-row">
            <div className="img-col">
              <div className="reactor-img">
                <img src={labImg} style={{width: '80px',display:'none'}} />
              </div>
              <div className="clock-img">
                <img src={clockImg} style={{width: '140px'}} />
              </div>
            </div>
            <img src={readingBoyImg} style={{width: '80%'}} />
            <div className="idea-img">
              <img src={ideadImg} style={{width: '140px', height: '140px'}} />
              <div className="bullseye-resp">
                <img src={bullseyeImg} style={{width: '80px'}} />
              </div>  
            </div>
          </div>
        </div>
        <div className="home-screen-right">
          <div className="home-screen-right-container">
            <div className="home-screen-subject">
            Flexagn is a daily learning platform that brings kids together through play
            </div>
            <div className="home-screen-text">
            Download our app 
            </div>
            <div className="download-row-container">
            <a href={playstore_link} target="_blank"
              class="play-store-badge"></a>
            {/* <form action="">
              <button className="play-store-badge"></button>
            </form> */}
            </div>
          </div>
        </div>
        <div className="bullseye">
          <img src={bullseyeImg} style={{width: '100%',display:'none'}} />
        </div>
      </div>
    </div>
        );
    }
}

export default HomePage;