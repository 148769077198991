import '../style/about-us.css';
import aboutLottie from '../assets/images/about-us.gif';

import React from 'react';

function AboutUsPage() {
    return (
        <div className="about-us" id='about_us'>
      <div className="about-us-container">
        <div className="about-us-texts" >
          <div className="about-us-title">About Us</div>
          <div className="about-us-header">
          Flexagn’s vision is to make daily learning a habit for kids without much parental involvement.
          </div>
          <div className="about-us-description">
          We are a team of passionate & experienced individuals with 10+ years of experience building social games and edtech products. We evaluated the gaps, challenges, and opportunities in the current edtech offering and strongly believed that learning for kids can be made much more fun, creative and engaging. Kids love to read comics or storybooks and play games on their phones. Flexagn’s learning pedagogy is entirely based on using the same like-able content format for kids and driving learning outcomes.
          </div>
        </div>
          <div className='about-container'>
            <div className="about-us-cards">
              <img src={aboutLottie} style={{ width: '-webkit - fill - available' }} />
            </div>
        </div>
      </div>
    </div>
    );
}

export default AboutUsPage;