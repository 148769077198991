import "../../style/download.css";
import "../../style/why-choose-flexagn.css";
import "../../style/wcf-card.css";
import { explore_link } from "../../constants/links";

import React from "react";

class DownloadComponent extends React.Component {
  render() {
    return (
      <div
        className="download-wrapper"
        style={{ backgroundColor: "#C23FA1", padding: "20px" }}
      >
        <div className="download" style={{ backgroundColor: "#C23FA1" }}>
          <div className="download-container">
            <div className="download-left">
              <div
                className="download-left-container"
                style={{ rowGap: "0px" }}
              >
                <div className="download-text" style={{ marginBottom: "0px" }}>
                  Make teaching and learning fun at school
                </div>
                <div className="download-text" style={{ width: "100%" }}>
                  Try out the platform for FREE. Request a product demo today!
                </div>
                <div
                  className="download-btn-list"
                  style={{ marginTop: "20px" }}
                >
                  <a
                    href="#contact_us"
                    style={{
                      textDecoration: "none",
                      color: "#C23FA1",
                      padding: "1.8% 4.5%",
                      margin: "0px",
                    }}
                    class="explore-btn"
                  >
                    Contact Us
                  </a>
                  {/* <div className="download-container">
                <button className="play-store-badge"></button>
              </div> */}
                </div>
              </div>
            </div>
            {/* <div className="download-right">
              <div className="download-right-container">
                <img
                  src={astronautImg}
                  alt=""
                  style={{ width: "80%", maxWidth: "220px", minWidth: "120px" }}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default DownloadComponent;
