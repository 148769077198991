import '../style/scholarships-made-for-you.css';

import elephantImg from '../assets/images/woolly-elephant.png';
import { explore_link } from '../constants/links';

import React from 'react';

const content = [
  {
    text: "Get rewarded for learning regularly"
  },
  {
    text: " All kids in grades 3-8 are eligible"
  },
  {
    text: "Register on our platform and apply"
  },
];

class ScholarshipsPage  extends React.Component {
    render() {
        return (
            <div className="smfy">
      <div className="smfy-container">
        <div className="smfy-left">
          <div className="smfy-left-container">
            <div className="smfy-title">
            Young Scholars Program
            </div>
            <div className="smfy-sub-title">
            100% exclusive scholarships for the brightest minds across the country.
            </div>
            <div className="smfy-list">
            {
              content.map((c, i) => (
                <>
                <div className="smfy-list-body">
                <span id='bulletian'/><li className="smfy-list-item">
                  {c.text}
              </li>
                </div>
              </>
              ))
            }
            </div>
           <div className='desktopsOnly'>
             
                    <a href={explore_link} target='_blank' style={{ textDecoration: "none", color: "white" }}
                      class="osm-explore-btns">EXPLORE</a>
           </div>
            {/* <button className="osm-explore-btn">EXPLORE</button> */}
          </div>
        </div>
        <div className="smfy-right">
          <div className="smfy-right-container">
            <img src={elephantImg} alt="this slowpoke moves" width="65%" style={{width: '65%', maxWidth: '550px'}} />
          </div>
        </div>
      </div>
    </div>
        );
    }
}

export default ScholarshipsPage;