import * as React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@mui/material/Typography";

import "../../style/frequently-asked-questions.css";
import crabImg from "../../assets/images/woolly-crab.png";

const faqs = [
  {
    question: "What is Flexgenie?",
    answer:
      "Flex genie is a creative assessment platform that provides schools with free tools to teach and assess anything and allows teachers to be more creative, and focussed and save time in classroom teaching. FlexGenie takes out the tedious elements of grading & assessments and makes evaluation more creative and fun.",
  },
  {
    question: "What makes Flexgenie different from other edtech providers?",
    answer:
      "Flexgenie is the most affordable and scalable learning & assessment platform for schools. Teachers can easily access 1000+ curated teaching and learning resources and make their in class teaching more effective and productive. Students can also enjoy the homework through our creative assessments.",
  },
  {
    question: "Is the platform free?",
    answer:
      "We are providing FREE trial for all the schools who are interested to use the platform. After the trial, schools have to pay very nominal fees to help us support the maintenance & upgradation cost of the platform.",
  },
  {
    question: "Can the content be downloaded?",
    answer:
      "No, our content cannot be downloaded. You need the app or web to interact and use all the learning content and simulations.",
  },
  {
    question: "What are the subjects the platform supports?",
    answer:
      "We currently offer content for Maths, Science, English, and Computers for Grades 3-8 across CBSE, ICSE and other state board curriculum.",
  },
  {
    question:
      "Do you provide any printed study material for students/teachers? ",
    answer:
      "No, at the moment we do not provide any printed study materials for the kids or teachers. They can access all the content by logging in to the platform.",
  },
  {
    question: "How do you reach out to customer support?",
    answer:
      "We have dedicated customer support for all the schools who are onboarded. You can also reach out to  us at school-support@flexagn.com or call us at 9886865380.",
  },
];

function FAQ() {
  return (
    <div className="faq" style={{ margin: "20px" }}>
      <div className="faq-container">
        <div className="crab-img" style={{ display: "none" }}>
          <img src={crabImg} width="100%" />
        </div>
        <div className="faq-header">
          <div className="faq-header-container">Frequently Asked Questions</div>
        </div>
        <div className="faq-body">
          <div className="faq-body-container">
            <div>
              {faqs.map((faq, i) => (
                <Accordion className="accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      className="accordion-header"
                      style={{
                        fontWeight: "medium",
                        fontSize: "17px",
                        fontFamily: "Poppins",
                      }}
                    >
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      className="accordion-body"
                      style={{ fontSize: "14px", fontFamily: "Poppins" }}
                    >
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
