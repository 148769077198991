import '../style/our-learning-framework.css';
import '../style/olf-card/olf-card.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import starImg from '../assets/images/star.png';
import gifImg1 from '../assets/images/online-learning.gif';
import gifImg2 from '../assets/images/gifs/gifImg2.gif';
import gifImg3 from '../assets/images/gifs/gifImg3.gif';
import gifImg4 from '../assets/images/gifs/gifImg4.gif';

import {React } from 'react';
import ReactDOM from 'react-dom';

const content = [
  {
    title: "Interactive Learning",
    body: "Engage with the content such as experiments and simulations and be hands-on in problem-solving"
  },
  {
    title: "Exploratory Learning",
    body: "Learn incrementally in a virtual scenario with open-ended learning objectives like lab simulations"
  },
  {
    title: "Self Learning",
    body: "Learn daily via content designed and personalized for every student’s learning journey using AI"
  },
  {
    title: "Collaborative Learning",
    body: "Learn with your friends together, solve problems, and complement others' strengths"
  },
];

var currIndex = -1;
function ChangeColor(i,active){
   document.getElementById(`olf-card-${i}`).style.backgroundColor='yellow';
  
}
function ChangeIndex(i) {
  currIndex = i;
  let element = (<img className="gif" src={gifImg1} />);
  if(currIndex == 0) {
    element = (<img className="gif" src={gifImg1} />);
  } else if(currIndex == 1) {
    element = (<img className="gif" src={gifImg2} />);
  } else if(currIndex == 2) {
    element = (<img className="gif" src={gifImg3} />);
  } else {
    element = (<img className="gif" src={gifImg4} />);
  }
  document.getElementById('default-gif').style.display = "none";
  ReactDOM.render(element, document.getElementById('gif_image'));
}
const responsive = {
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const CustomDotOlf = ({ onMove, index, onClick, active}) => {
  return (
    <button
      className={active ? "activeolf" : "inactiveolf"}
      onClick={() => {onClick()
      ChangeIndex(index)
      ChangeColor(index,active)
      }}
    >
    </button>
  );
};

function OlpPage() {
    return (
    <div className="olf">
      <div className="olf-container">
          <div className="star" style={{  display: 'none' }}>
          <img src={starImg} width="100%" />
        </div>
        <div className="olf-heading">
        Our Learning Framework
        </div>
        <div className="olf-body">
          <div className="olf-body-container">
            <div className="olf-left">
              <div className="olf-left-container">
                {
                  currIndex == -1 
                  ? <img className="gif" src={gifImg1} id="default-gif" />
                  : <div ></div>
                }
                <div id="gif_image"></div>
              </div>
            </div>
            <div className="olf-right">
              <div className="olf-right-container">
                {
                  content.map((c, i) => (
                    <div className="olf-card" onMouseEnter={e => ChangeIndex(i)}>
                    <div className="olf-card-container">
                      <div className="olf-card-header">
                        {c.title}
                      </div>
                      <div className="olf-card-description">
                        {c.body}
                      </div>
                    </div>
                  </div>
                  ))
                }
              </div>
            </div>
            <div className="olf-carousel-body">
            <Carousel
          partialVisbile={false}
  swipeable={false}
  draggable={false}
  showDots={true}
  customDot={<CustomDotOlf/>}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={false}
  autoPlaySpeed={1000000}
  keyBoardControl={true}
  containerClass="carousel-container-olf"
  removeArrowOnDeviceType={["mobile","tablet"]}
  deviceType={["mobile","tablet"]}
  dotListClass="custom-dot-list-style-olf"
  itemClass='olf-item-carousel'
>
  
  {
                  content.map((c, i) => (
                    <div className="olf-card">
                    <div className="olf-card-container">
                      <div className="olf-card-header">
                        {c.title}
                      </div>
                      <div className="olf-card-description">
                        {c.body}
                      </div>
                    </div>
                  </div>
                  ))
                }
     
</Carousel>
</div>

           
          </div>
        </div>
      </div>
    </div>
    );
}

export default OlpPage;