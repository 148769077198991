import React from "react";
import styled from "styled-components";
import { Auth } from "../Db";

const Header = styled.span`
  font: normal normal 600 24px Poppins;
`;
const SubHeader = styled.span`
  font: normal normal normal 15px Poppins;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 10px;
`;
const Form = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled.div`
  display: flex;
  @media (max-width:700px){
    width:250px;
    height:30px;
    margin: auto;
  }
`;
const Input = styled.input`
  width: 72px;
  height: 50px;
  border: 1px solid #185dd2;
  border-radius: 8px;
  box-shadow: 0px 3px 10px #0000001a;
  margin-right: 20px;
  border: none;
  outline: none;
  text-align: center;
  font: normal normal 600 16px/25px Poppins;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal normal 14px/25px Poppins;
    color: #b5b5b5;
  }
  @media (max-width:700px){
    height:25px;
    width:36px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
const Button = styled.button`
  width: 335px;
  margin: auto;
  padding: 6px 12px;
  font: normal normal 600 16px Poppins;
  background-color: #185dd2;
  color: white;
  border: none;
  border-radius: 90px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 3px 6px #0000000d;
  @media (max-width:700px){
    width:250px;
  }
`;
const ResendWrapper = styled.div`
  width: 70%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
`;

const Resend = styled.span`
  font: normal normal normal 12px/18px Poppins;
  color: #6f6f6f;
`;

const Otp = (props) => {
  let handleKey = (first, last) => {
    if (first.target.value.length) {
      document.getElementById(last).focus();
      console.log("working");
    }
  };
  const handleClick=()=>{
    props.setOpen(false);
  }
  return (
    <>
        <Header>{Auth[0].otp.header}</Header>
        <SubHeader>
        {Auth[0].otp.subheader}
        </SubHeader>
        <Form>
          <InputContainer>
            <Input
              type="text"
              id="first"
              maxLength="1"
              onKeyUp={(e) => handleKey(e, "second")}
            />
            <Input
              type="text"
              id="second"
              maxLength="1"
              onKeyUp={(e) => handleKey(e, "third")}
            />
            <Input
              type="text"
              id="third"
              maxLength="1"
              onKeyUp={(e) => handleKey(e, "fourth")}
            />
            <Input type="text" id="fourth" maxLength="1" />
          </InputContainer>
          <ButtonWrapper>
              <Button onClick={handleClick}>Continue</Button>
          </ButtonWrapper>
         <ResendWrapper>
           <Resend>{Auth[0].otp.disclaimer}</Resend>
         </ResendWrapper>
        </Form>
    </>
  );
};

export default Otp;
