import '../style/features-at-your-fingertips.css';
import '../style/fayf-card/fayf-card.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import starImg from '../assets/images/star.png';

import gif1Img from '../assets/images/fayf-gifs/gif1.png';
import gif2Img from '../assets/images/fayf-gifs/gif2.png';
import gif3Img from '../assets/images/fayf-gifs/gif3.png';
import gif4Img from '../assets/images/fayf-gifs/gif4.png';

import React from 'react';
import ReactDOM from 'react-dom';

const content = [
  {
    title: "Comics, Storybooks & More",
    body: "Get your daily dose of learning via comics, storybooks, and mini-games. Concepts from Math, Science, EVS, and computers are personalized and adapted to each student’s caliber and progress."
  },
  {
    title: "Playgrounds",
    body: "Get an immersive learning experience with games, simulations, and explorations. Engage kids through an intuitive environment where they learn through exploration and discovery."
  },
  {
    title: "Connect with Friends",
    body: "Studying with friends is fun. Connect with friends, challenge, collaborate, and complete tasks together. Be part of learning groups and complete team missions."
  },
  {
    title: "Track Learning Progress",
    body: "Track the child’s learning progress with easy-to-understand graphs and reports on the parent dashboard. Know your child's strengths and weaknesses at a concept level with details."
  },
];

var currIndex = -1;  

function ChangeIndex(i) {
  currIndex = i;
  let element = (<img className="gif" src={gif1Img} />);
  if(currIndex == 0) {
    element = (<img className="gif" src={gif1Img} />);
  } else if(currIndex == 1) {
    element = (<img className="gif" src={gif2Img} />);
  } else if(currIndex == 2) {
    element = (<img className="gif" src={gif3Img} />);
  } else {
    element = (<img className="gif" src={gif4Img} />);
  }
  document.getElementById('default-fayf-gif').style.display = "none";
  ReactDOM.render(element, document.getElementById('fayf_gif_image'));
}
const responsive = {
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};
const CustomDotFayf = ({ onMove, index, onClick, active}) => {
  return (
    <button
      className={active ? "activeolf" : "inactiveolf"}
      onClick={() => {onClick()
      ChangeIndex(index)
      }}
    >
    </button>
  );
};
class FeaturePage  extends React.Component {
    
  
  render() {
        return (
            <div className="fayf">
      <div className="fayf-header">
      Flexagn features at your fingertips
      </div>
      <div className="fayf-container">
        <div className="fayf-left">
          {/* <!-- <div className="fayf-header">
            FEATURES AT YOUR FINGERTIPS
          </div> --> */}
          <div className="fayf-card-list">
            {/* fayf-card */}
            {
              content.map((c, i) => (
                <div className="fayf-card" onMouseEnter={e => ChangeIndex(i)}>
                <div className="fayf-card-container">
                  <div className="fayf-card-header">
                    {c.title}
                  </div>
                  <div className="fayf-card-description">
                    {c.body}
                  </div>
                </div>
              </div>
              ))
            }
          </div>
        </div>
      

     <div className="fayf-carousel-body">

          <Carousel
          customDot={<CustomDotFayf/>}
          partialVisbile={false}
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={false}
  autoPlaySpeed={1000000}
  keyBoardControl={true}
  containerClass="carousel-container-fayf"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={["mobile","tablet"]}
  dotListClass="custom-dot-list-style-fayf"
  itemClass='fayf-item-carousel'
>
  
   {
              content.map((c, i) => (
                <div className="fayf-card">
                <div className="fayf-card-container">
                  <div className="fayf-card-header">
                    {c.title}
                  </div>
                  <div className="fayf-card-description">
                    {c.body}
                  </div>
                </div>
              </div>
              ))
            }
     
</Carousel>
</div>
        <div className="fayf-right">
          <div className='fayf-right-container'>
            <div className='fayf-star'>
            <img src={starImg} width="100%" />
            </div>
            <div className='gif-container'>
              {/* <img src={gif1Img} width="100%" /> */}
              {
                currIndex == -1
                ? <img className="gif" src={gif1Img} id="default-fayf-gif" />
                : <div></div>
              }
              <div id="fayf_gif_image"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
        );
    }
}

export default FeaturePage;