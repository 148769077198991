import "../../style/scholarships-made-for-you.css";

import all_in_one from "../../assets/flexgenie_images/all_in_one.png";
import { explore_link } from "../../constants/links";

import React from "react";

const content = [
  {
    text: "Lesson plans",
  },
  {
    text: "Interactive quiz",
  },
  {
    text: "Summary Notes, Student Notes & Q&A",
  },
  {
    text: "Creative assessments",
  },
  {
    text: "Worksheets & Flashcards",
  },
  {
    text: "Exam Schedules",
  },
];

class ScholarshipComponent extends React.Component {
  render() {
    return (
      <div className="smfy-flexgenie">
        <div className="smfy-container">
          <div className="smfy-left-flexgenie">
            <div className="smfy-left-container">
              <div className="smfy-title">What content do we offer ?</div>
              <div className="smfy-sub-title">
                High-quality and interactive content designed by experts
              </div>
              <div className="smfy-list">
                {content.map((c, i) => (
                  <>
                    <div className="smfy-list-body">
                      <span id="bulletian" />
                      <li className="smfy-list-item">{c.text}</li>
                    </div>
                  </>
                ))}
              </div>
              <div className="desktopsOnly">
                <a
                  href="https://play.google.com/store/apps/details?id=com.fastlabz.flexgenie&referrer=utm_source%3Dwebsite"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    backgroundColor: "#C23FA1",
                  }}
                  class="osm-explore-btns"
                >
                  EXPLORE
                </a>
              </div>
              {/* <button className="osm-explore-btn">EXPLORE</button> */}
            </div>
          </div>
          <div className="smfy-right-flexgenie">
            <div className="smfy-right-container">
              <img
                src={all_in_one}
                alt="this slowpoke moves"
                width="65%"
                style={{ width: "65%", maxWidth: "550px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ScholarshipComponent;
