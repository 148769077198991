import "../../style/our-subscription-model.css";
import "../../style/subscription-card/subscription-card.css";

import img1 from "../../assets/flexgenie_images/benefits_icons/1.png";
import img2 from "../../assets/flexgenie_images/benefits_icons/2.png";
import img3 from "../../assets/flexgenie_images/benefits_icons/3.png";
import img4 from "../../assets/flexgenie_images/benefits_icons/4.png";

import React from "react";

const content = [
  {
    // title: "For 12 months",
    body: "Exclusive branding for schools including free website, social media & in-app promotions. Boost up to 10% enrollments",
  },
  {
    // title: "For 6 months",
    body: "Give access to high-quality curriculum content to teachers and students. Improve student Grades",
  },
  {
    // title: "For 3 months",
    body: "Save teachers time & administration costs. Fully automated and affordable learning platform",
  },
  {
    // title: "For 3 months",
    body: "24x7 Customer Support & Dedicated Account Manager for schools",
  },
];

class SubscriptionComponent extends React.Component {
  render() {
    return (
      <div className="osm" style={{ backgroundColor: "#FAF2FA" }}>
        <div className="osm-container osm-container-flexgenie">
          <div className="osm-header">
            <div className="osm-header-container">
              <div className="osm-header-left">
                <img
                  src={img1}
                  alt=""
                  width="100%"
                  style={{ width: "100%", maxHeight: "550px" }}
                />
              </div>
              <div className="osm-header-right">
                <div className="osm-header-right-container">
                  <div className="osm-header">
                    World-class Content. Affordable Pricing
                  </div>
                  <div className="osm-sub-header">
                    Flexagn is offering Maths, Science concepts & more with
                    unlimited access. New learning modules every day. Cancel
                    anytime and get a full refund*
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="osm-body" style={{ backgroundColor: "#C23FA1" }}>
            <div>
              <p className="osm-body-header" style={{ color: "white" }}>
                Benefits For Schools
              </p>
              <p className="osm-subheader-flexgenie" style={{ color: "white" }}>
                Transform your school to become the best in your area
              </p>
            </div>
            <div />
            <div className="osm-body-container">
              {/* <!-- subs-card --> */}
              <div className="subs-flexgenie">
                <div className="subs-card-flexgenie">
                  {/* <div className="subs-card-2-header">{content[0].title}</div> */}
                  <div className="subs-card-desc-flexgenie">
                    {content[0].body}
                  </div>
                  <div className="subs-card-4-img">
                    <img
                      src={img1}
                      className="subscription-img"
                      alt="loading..."
                    />
                  </div>
                </div>
              </div>
              {/* <!-- subs-card --> */}
              <div className="subs-flexgenie">
                <div className="subs-card-flexgenie">
                  {/* <div className="subs-card-2-header">{content[1].title}</div> */}
                  <div className="subs-card-desc-flexgenie">
                    {content[1].body}
                  </div>
                  <div className="subs-card-4-img">
                    <img
                      src={img2}
                      className="subscription-img"
                      alt="loading..."
                    />
                  </div>
                </div>
              </div>
              {/* <!-- subs-card --> */}
              <div className="subs-flexgenie">
                <div className="subs-card-flexgenie">
                  {/* <div className="subs-card-2-header">{content[2].title}</div> */}
                  <div className="subs-card-desc-flexgenie">
                    {content[2].body}
                  </div>
                  <div className="subs-card-4-img">
                    <img
                      src={img3}
                      className="subscription-img"
                      alt="loading..."
                    />
                  </div>
                </div>
              </div>
              <div className="subs-flexgenie">
                <div className="subs-card-flexgenie">
                  <div className="subs-card-desc-flexgenie">
                    {content[3].body}
                  </div>
                  <div className="subs-card-4-img">
                    <img
                      src={img4}
                      className="subscription-img"
                      alt="loading..."
                    />
                  </div>
                </div>
              </div>
              <div
                className="osm-footer"
                style={{ backgroundColor: "#FAF2FA" }}
              >
                <div
                  className="osm-footer-container"
                  style={{ backgroundColor: "#C23FA1" }}
                >
                  <a
                    href="https://play.google.com/store/apps/details?id=com.fastlabz.flexgenie&referrer=utm_source%3Dwebsite"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#C23FA1",
                      backgroundColor: "white",
                    }}
                    class="osm-explore-btn"
                  >
                    EXPLORE
                  </a>
                  {/* <button className="osm-explore-btn">EXPLORE</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubscriptionComponent;
