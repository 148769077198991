import React from "react";
import AboutUsPage from "../about-us";
import AppFooter from "../app-footer";
import ContactUsPage from "../contact-us";
import DownloadPage from "../download";
import FeaturePage from "../features";
import FaqPage from "../frequently-asked-questions";
import HeaderPage from "../header";
import HomePage from "../home";
import NewsPage from "../news";
import OlpPage from "../our-learning-framework";
import ScholarshipsPage from "../scholarships";
import SubscriptionPage from "../subscription";
import TestimonialsPage from "../testimonials";
import WcfPage from "../why-choose-flexagn";

const LandingPage = () => {
  return (
    <>
      <HeaderPage />
      <HomePage />
      <WcfPage />
      <OlpPage />
      <FeaturePage />
      <AboutUsPage />
      <SubscriptionPage />
      <TestimonialsPage />
      <ScholarshipsPage />
      <NewsPage />
      <DownloadPage />
      <FaqPage />
      <ContactUsPage />
      <AppFooter />
    </>
  );
};

export default LandingPage;
