import "../style/flexagn-footer.css";

import facebookImg from "../assets/images/social-media/facebook.svg";
// import twitterImg from "../assets/images/social-media/twitter.svg";
// import instagramImg from "../assets/images/social-media/instagram.svg";
import youtubeImg from "../assets/images/social-media/youtube.png";
import linkedInImg from "../assets/images/social-media/linkedin.svg";
// import englandFlag from "../assets/images/flags/england.png";
import indiaFlag from "../assets/images/flags/india.png";
import {worksheetData} from "../Db";
// import usaFlag from "../assets/images/flags/usa.png";
import MobileModel from '../components/MobileModel';
import React, { useState } from "react";
// import { YouTube } from "@material-ui/icons";
const AppFooter=()=> {
  if(!localStorage.getItem('limit')){
    localStorage.setItem('limit',2);
  }
  // const [cookies,setCookie]=useCookies('limit');
  // let val=3;
  // useEffect(()=>{
  //   setCookie('limit',val, { path: '/' });
  // },[])
  // localStorage.setItem('limit',3);
  const [update, openUpdate] = useState(false);
  const [val,setVal]=useState(localStorage.getItem('limit'));
    const handleClick = () => {
      openUpdate(true);
      console.log(localStorage.getItem('limit'))
    };
  const handleLimit=()=>{
    // if(cookies.limit!==0){
    // setCookie('limit',val-1, { path: '/' });

    // }
    if((localStorage.getItem('limit'))>0){
      let newVal=parseInt(localStorage.getItem('limit'));
      newVal-=1;
      localStorage.setItem('limit',newVal);
      setVal(localStorage.getItem('limit'));
    }
  }
    return (<>
      <div className="app-footer">
        <div className="app-footer-container">
          <div className="app-footer-top">
            <div className="app-footer-top-container">
              <div className="top-c1">
                <div className="app-footer-flexagn">FLEXAGN</div>
                <div className="c1-description">
                  Flexagn offers the best in educational apps for children ages
                  12 and under! Our fun, child-friendly, interactive educational
                  content fuels their curiosity. It enables them to explore
                  their interests and develop their critical thinking and
                  problem-solving skills through access to various new content
                  every day of the year.
                </div>
                <div className="c1-copyright">
                  ©Copyright all rights are reserved
                </div>
              </div>
              <div className="top-c2">
                {/* <!-- Math Program --> */}
                {/* <div className="topic-container">
                  <div className="topic-header">Math Program</div>
                  <ul>
                    <li className="topic-item">Home Tuitions </li>
                    <li className="topic-item">Home Tutors </li>
                    <li className="topic-item">Private Tutors </li>
                  <li className="topic-item">Tuition Tutors </li>
                  <li className="topic-item">Online Tuitions </li>
                  <li className="topic-item">Online Math Courses</li>
                  </ul>
                </div> */}

                {/* <!-- Math Worksheets --> */}
                <div className="topic-container">
                  <div className="topic-header">Math Worksheets</div>
                  <ul>
                    {/* <li className="topic-item">
                      Kindergarten Math Worksheets{" "}
                    </li> */}
                    {/* <li className="topic-item">1st Grade Math Worksheets </li> */}
                    {/* <li className="topic-item">2nd Grade Math Worksheets </li>
                  <li className="topic-item">3rd Grade Math Worksheets </li>
                  <li className="topic-item">5th Grade Math Worksheets </li>
                  <li className="topic-item">6th Grade Math Worksheets </li>
                  <li className="topic-item">7th Grade Math Worksheets </li>
                  <li className="topic-item">8th Grade Math Worksheets </li>
                  <li className="topic-item">9th Grade Math Worksheets </li>
                  <li className="topic-item">10th Grade Math Worksheet</li> */}
                 {worksheetData.map((e)=>{
                   return(
                     <>
                     {
                       val==='0'? <li style={{cursor:"pointer"}} className="topic-item" onClick={handleClick}>{e.title}</li>:<li className="topic-item" onClick={handleLimit}><a href={e.url} target="_blank">{e.title}</a></li>
                     }
                     </>
                   )
                 })}
                  </ul>
                </div>
              </div>

              {/* <!-- Quick Links --> */}
              {/* <div className="top-c3">
              <div className="topic-container">
                <div className="topic-header">Quick Links</div>
                <ul>
                  <li className="topic-item">Maths Olympiad (IMO) </li>
                  <li className="topic-item">Math Questions </li>
                  <li className="topic-item">Math Puzzle </li>
                  <li className="topic-item">Math Games </li>
                  <li className="topic-item">Math Teachers </li>
                  <li className="topic-item">Teacher Jobs </li>
                  <li className="topic-item">Part Time Jobs </li>
                  <li className="topic-item">Online Tutoring Jobs </li>
                  <li className="topic-item">Blog - Parents </li>
                  <li className="topic-item">Blog - Teachers</li>
                </ul>
              </div>
            </div> */}

              {/* <!-- Math Topics --> */}
              {/* <div className="top-c4">
              <div className="topic-container">
                <div className="topic-header">Math Topics</div>
                <ul>
                  <li className="topic-item">Numbers </li>
                  <li className="topic-item">Algebra </li>
                  <li className="topic-item">Geometry </li>
                  <li className="topic-item">Data Measurement </li>
                  <li className="topic-item">Commercial Math </li>
                  <li className="topic-item">Trigonometry </li>
                  <li className="topic-item">Calculus </li>
                  <li className="topic-item">Math Formulas </li>
                  <li className="topic-item">Calculators </li>
                  <li className="topic-item">Multiplication Tables </li>
                  <li className="topic-item">NCERT Solutions NCERT Solutions for Class 7 Maths </li>
                  <li className="topic-item">NCERT Solutions for Class 8 Maths </li>
                  <li className="topic-item">NCERT Solutions for Class 9 </li>
                  <li className="topic-item">Maths NCERT Solutions for Class 10 Maths</li>
                </ul>
              </div>
            </div> */}
              <div className="top-c5">
                {/* <!-- Social Media --> */}
                <div className="topic-container">
                  <div className="topic-header">Social Media</div>
                  <ul>
                    <li className="social-media-item">
                      <div className="social-media">
                        <div className="social-media-container">
                          <div className="social-media-img">
                            <img src={facebookImg} alt="loading..."/>
                          </div>
                          <a
                            className="social-media-text"
                            href="https://www.facebook.com/flexagn"
                            target="_blank"
                          >
                            Facebook
                          </a>
                        </div>
                      </div>
                    </li>
                    {/* <li className="social-media-item">
                    <div className="social-media">
                      <div className="social-media-container">
                        <div className="social-media-img">
                          <img src={twitterImg} alt="" />
                        </div>
                        <div className="social-media-text">
                          Twitter
                        </div>
                    </div>
                  </div> 
                  </li> */}
                    {/* <li className="social-media-item">
                    <div className="social-media">
                      <div className="social-media-container">
                        <div className="social-media-img">
                          <img src={instagramImg} alt="" />
                        </div>
                        <div className="social-media-text">
                          Instagram
                        </div>
                    </div>
                  </div> 
                  </li> */}
                    <li className="social-media-item">
                      <div className="social-media">
                        <div className="social-media-container">
                          <div className="social-media-img">
                            <img src={linkedInImg} alt="loading..."/>
                          </div>
                          <a
                            className="social-media-text"
                            href="https://www.linkedin.com/company/flexagn/?viewAsMember=true"
                            target="_blank"
                          >
                            Linkedin
                          </a>
                        </div>
                      </div>
                    </li>
                    <li className="social-media-item">
                      <div className="social-media">
                        <div className="social-media-container">
                          <div className="social-media-img">
                            <img
                              src={youtubeImg}
                              style={{
                                width: "35px",
                                height: "30px",
                                marginTop: "-4px",
                                marginLeft: "-6px",
                              }}
                            />
                          </div>
                          <a
                            className="social-media-text"
                            href="https://www.youtube.com/channel/UCXXJSExZQFDqgITuC7COT-g"
                            target="_blank"
                          >
                            Youtube
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="app-footer-bottom">
            <div className="app-footer-bottom-container">
              <div className="app-footer-bottom-left">
                <div className="country-support">Country support</div>
                <div className="countries">
                  <div className="country">
                    <div className="flag-img">
                      <img src={indiaFlag} alt="" style={{ width: "30px" }} />
                    </div>
                    <div className="country-name">India</div>
                  </div>
                  {/* <div className="country">
                  <div className="flag-img">
                    <img src={usaFlag} alt="" style={{width: '30px'}}/>
                  </div>
                  <div className="country-name">USA</div>
                </div>
                <div className="country">
                  <div className="flag-img">
                    <img src={englandFlag} alt="" style={{width: '30px'}} />
                  </div>
                  <div className="country-name">England</div>
                </div> */}
                </div>
              </div>
              <div className="app-footer-bottom-right">
                <div className="footer-policy"><a href='https://d2dsj7nm5j2dtv.cloudfront.net/pp.html' target='_blank'>Privacy Policy</a></div>
                <div className="footer-policy"><a href='https://d2dsj7nm5j2dtv.cloudfront.net/tos.html' target='_blank'>Terms & Conditions</a></div>
                {/* <div className="footer-policy">Cookie Policy</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MobileModel open={update} setOpen={openUpdate} />
      </>
    );
  }

export default AppFooter;
