import "../../style/why-choose-flexagn.css";
import "../../style/wcf-card.css";

import img1 from "../../assets/images/wcf-card/1.png";
import img2 from "../../assets/images/wcf-card/2.png";
import img3 from "../../assets/images/wcf-card/3.png";
import img4 from "../../assets/images/wcf-card/4.png";
import octo from "../../assets/images/octopus.png";
import mask from "../../assets/images/mask.png";
import { explore_link } from "../../constants/links";

import React from "react";

const content = [
  {
    title: "Branding For Schools",
    body: "Schools get exclusive branding and promotions across platforms which helps boost new enrolments",
  },
  {
    title: "High-Quality Content",
    body: "Unlimited access to 1000+ of high-quality content for teachers & students to improve teaching & learning",
  },
  {
    title: "Save Time & Resources",
    body: "A fully automated system that reduces teachers' time to assign and grade assessments",
  },
  {
    title: "Improve Student Grades",
    body: "Teachers get real-time insights and help students perform better via constant feedback. Easy monitoring of progress for parents",
  },
];

class KeyPoint extends React.Component {
  render() {
    return (
      <div className="wcf">
        <div className="wcf-container wcf-container-flexgenie">
          <div className="octopus">
            <img src={octo} style={{ width: "100%", display: "none" }} />
          </div>
          <div className="wcf-container-left">
            <div className="wcf-container-left-container">
              {/* <div className="mask">
                <img src={mask} style={{ width: "100%" }} />
              </div> */}
              <div className="text-1" style={{ marginBottom: "17px" }}>
                Engage with students in classrooms and beyond!
              </div>
              <div className="text-2" style={{ marginBottom: "0" }}>
                A platform exclusively designed for schools to improve their
                branding, reduce costs, and save teachers' time by helping them
                find the right resources and improve student grades
              </div>
              <div className="desktopOnly">
                <a
                  href="https://play.google.com/store/apps/details?id=com.fastlabz.flexgenie&referrer=utm_source%3Dwebsite"
                  target="_blank"
                  style={{ textDecoration: "none", color: "#C23FA1" }}
                  class="explore-btn"
                >
                  EXPLORE OUR APP
                </a>
              </div>
            </div>
          </div>
          <div className="wcf-container-right">
            {/* <!-- card-1 --> */}
            <div className="wcf-card">
              <div className="card-container">
                <div className="card-img">
                  <div className="card-img-container">
                    <img src={img1} className="card-img" />
                  </div>
                </div>
                {/* <div className="card-header">{content[0].title}</div> */}
                <div className="card-description">{content[0].body}</div>
              </div>
            </div>
            {/* <!-- card-2 --> */}
            <div className="wcf-card">
              <div className="card-container">
                <div className="card-img">
                  <div className="card-img-container">
                    <img src={img2} className="card-img" />
                  </div>
                </div>
                {/* <div className="card-header">{content[1].title}</div> */}
                <div className="card-description">{content[1].body}</div>
              </div>
            </div>
            {/* <!-- card-3 --> */}
            <div className="wcf-card">
              <div className="card-container">
                <div className="card-img">
                  <div className="card-img-container">
                    <img src={img3} className="card-img" />
                  </div>
                </div>
                {/* <div className="card-header">{content[2].title}</div> */}
                <div className="card-description">{content[2].body}</div>
              </div>
            </div>
            {/* <!-- card-4 --> */}
            <div className="wcf-card">
              <div className="card-container">
                <div className="card-img">
                  <div className="card-img-container">
                    <img src={img4} className="card-img" />
                  </div>
                </div>
                {/* <div className="card-header">{content[3].title}</div> */}
                <div className="card-description">{content[3].body}</div>
              </div>
            </div>
            <div className="onlyMobile">
              <a
                href={explore_link}
                style={{ textDecoration: "none", color: "#185DD2" }}
                class="explore-btn"
              >
                EXPLORE OUR APP
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KeyPoint;
