import '../style/download.css';

import astronautImg from '../assets/images/astronaut.png';
import { playstore_link } from '../constants/links';

import React from 'react';

class DownloadPage  extends React.Component {
    render() {
        return (
          <div className='download-wrapper'>
            <div className="download">
      <div className="download-container">
        <div className="download-left">
          <div className="download-left-container">
            <div className="download-text">
              Download the Flexagn app today, create a profile for your child and never stop learning!
            </div>
            <div className="download-btn-list">
              <div className="download-container">
                <a href={playstore_link}
                class="play-store-badge"></a>
                {/* <button className="play-store-badge"></button> */}
              </div>
              {/* <div className="download-container">
                <button className="play-store-badge"></button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="download-right">
          <div className="download-right-container">
            <img src={astronautImg} alt="" style={{width: '80%', maxWidth: '220px', minWidth: '120px'}} />
          </div>
        </div>
      </div>
    </div>
    </div>
        );
    }
}

export default DownloadPage;